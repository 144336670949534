import React from 'react';
import clsx from 'clsx';

import * as styles from './DownloadEntry.module.css';

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const DownloadEntry = ({ entry, downloadFunction }) => {
  const { title, preview, processedPreviewImage } = entry;
  return (
    <div className={styles.content}>
      <div className={styles.nameAndPreviewContainer}>
        <div className={styles.name}>{title}</div>
        <div className={styles.preview}>
          {processedPreviewImage ? (
            <img alt={title} src={processedPreviewImage.publicURL} />
          ) : preview ? (
            <img src={preview} alt={title} />
          ) : null}
        </div>
      </div>
      <div className={styles.files}>
        <ul>
          {entry.files.map((file) => {
            const className = `rnd${guid()}`;
            return (
              <li key={file.url}>
                <a
                  href={file.url}
                  target="_blank"
                  onClick={(e) => {
                    downloadFunction(file.url, className);
                    e.preventDefault();
                  }}
                >
                  <span className={clsx(styles.downloadIcon, 'icon-download')} />
                  {file.name}
                </a>
                <a href={file.url} target="_blank" className={className} download style={{ display: 'none' }}>
                  {file.url}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
