import React from 'react';
import Skylight from 'react-skylight';

import * as styles from './Downloads.module.css';

import CloseIcon from './../../assets/images/close-icon.svg';
import { PageTitle } from './PageTitle';
import { Button } from './Button';
import { DownloadEntry } from './DownloadEntry';

function getSkylightStyles() {
  // eslint-disable-next-line no-undef
  const width = typeof window !== 'undefined' ? window.innerWidth : 0;
  // eslint-disable-next-line no-undef
  const height = typeof window !== 'undefined' ? window.innerHeight : 0;

  const mobile = width > 0 && width <= 600;
  const tablet = width > 0 && width <= 900;
  const landscape = height < width;

  if (mobile && !landscape) {
    return {
      height: 'auto',
      marginTop: '0',
      width: '100%',
      left: 0,
      marginLeft: 0,
      top: 75,
      textAlign: 'center',
    };
  } else if (mobile && landscape) {
    return {
      height: 'auto',
      top: '50px',
      marginTop: '-25px',
      width: '60%',
      left: '50%',
      marginLeft: '-30%',
      textAlign: 'center',
    };
  } else if (tablet) {
    return {
      height: 'auto',
      textAlign: 'center',
    };
  }

  return {
    height: 'auto',
    width: 400,
    minHeight: 220,
    marginLeft: -200,
    textAlign: 'center',
  };
}

const closeButtonStyle = {
  color: 'transparent',
  right: 0,
  top: 0,
  height: 40,
  width: 40,
  borderRadius: 20,
  textAlign: 'center',
  display: 'table-cell',
  verticalAlign: 'middle',
  background: `#fff url("${CloseIcon}") no-repeat center center / 60% 60%}`,
};

export const Downloads = ({ data, manufacturers, downloads }) => {
  // eslint-disable-next-line no-undef
  const that = typeof window !== 'undefined' ? window : this;

  const processedDownloads = [];

  const predownloadFunction = (name, className) => {
    that.storedFileLinkClass = className;
    that.skylight.show();
  };

  const downloadFile = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    document.querySelector(`.${that.storedFileLinkClass}`).click();

    that.skylight.hide();
  };

  downloads &&
    downloads.edges.forEach(({ node }) => {
      const { title, image, category, files } = node;

      const entryData = {
        title,
        processedPreviewImage: image ? image : null,
        preview: image ? image : null,
        files: [],
      };

      files.forEach((file) => {
        entryData.files.push({
          name: file.name,
          url: file.image.publicURL,
        });
      });

      processedDownloads[category] = downloads[category] || [];
      processedDownloads[category].push(entryData);
    });

  const downloadsHersteller = manufacturers.edges
    .map(({ node }) => {
      const { logopng, logojpg, logosvg, logoai, name } = node;

      const hasPng = !!logopng;
      const hasJpg = !!logojpg;
      const hasSvg = !!logosvg;
      const hasAi = !!logoai;

      const previewImage = hasPng ? logopng.publicURL : hasSvg ? logosvg.publicURL : hasJpg ? logojpg.publicURL : '';

      const processedPreviewImage = hasPng ? logopng : hasJpg ? logojpg : null;

      const entryData = {
        title: name,
        preview: previewImage || null,
        processedPreviewImage: processedPreviewImage || null,
        files: [],
      };

      if (hasSvg) {
        entryData.files.push({
          name: 'Logo (SVG)',
          url: logosvg.publicURL,
        });
      }
      if (hasAi) {
        entryData.files.push({
          name: 'Logo (AI)',
          url: logoai.publicURL,
        });
      }
      if (hasPng) {
        entryData.files.push({
          name: 'Logo (PNG)',
          url: logopng.publicURL,
        });
      }
      if (hasJpg) {
        entryData.files.push({
          name: 'Logo (JPG)',
          url: logojpg.publicURL,
        });
      }

      if (entryData.files.length > 0) {
        return entryData;
      }
      return null;
    })
    .filter((download) => download);

  return (
    <section className="defaultWidth">
      {processedDownloads['EZ Agrar'] &&
        processedDownloads['EZ Agrar'].map((download) => (
          <DownloadEntry entry={download} key={download.title} downloadFunction={predownloadFunction} />
        ))}

      <PageTitle>Hersteller</PageTitle>
      <div className={styles.manufacturerLogos}>
        {downloadsHersteller.map((download) => (
          <DownloadEntry
            entry={download}
            key={`${download.title}${download.preview}`}
            downloadFunction={predownloadFunction}
          />
        ))}
      </div>
      {typeof document !== 'undefined' && (
        <Skylight
          hideOnOverlayClicked
          ref={(ref) => {
            that.skylight = ref;
          }}
          transitionDuration={500}
          dialogStyles={getSkylightStyles()}
          closeButtonStyle={closeButtonStyle}
        >
          <br />
          <p>
            Je nach Hersteller gelten verschiedene Richtlinien wie und wofür die Logos verwendet werden dürfen. Sie sind
            selbst dafür verantwortlich sich entsprechend zu informieren und die Logos nur richtliniengemäß zu
            verwenden.
          </p>
          <Button color="blue" onClick={downloadFile} to={'/'}>
            Download
          </Button>
        </Skylight>
      )}
    </section>
  );
};
